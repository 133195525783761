<template>
  <BaseballAddPlayersModal
    :team-name="team.name"
    :players-to-add="playersToAdd"
    :all-players-to-add="allPlayersToAdd"
    :is-visible="isAddPlayersModalActive"
    :player-tabs="baseballPlayersTabs"
    :active-tab="activeTab"
    :team-id="team.teamId"
    @onClose="onModalClose"
    @onAddSelectedPlayers="addSelectedPlayers"
    @onSelectedTabChange="onTabChange"
  />
  <div class="baseball-team-params-root">
    <div class="baseball-team-params-header">
      <div class="team-info">
        <p class="team-name">
          {{ team.name }}
        </p>
        <div
          class="lineup-indicator"
          :class="{ 'lineup-available': hasTeamLineup }"
        >
          <Tooltip
            :text="hasTeamLineup ? 'Lineup is available' : 'No lineup info'"
            right
          >
            <Icon
              :name="hasTeamLineup ? 'users-check' : 'users-x'"
            />
          </Tooltip>
        </div>
      </div>
      <div class="header-actions">
        <TextInput
          class="player-search-input"
          :model-value="searchValue"
          icon="search"
          :placeholder="'Search players'"
          small
          @update:model-value="updatePlayerSearch"
        />
        <Button
          class="add-players-btn"
          variant="primary"
          @click="addPlayers"
        >
          <Icon name="user-plus-01" />
          <span>Players</span>
        </Button>
      </div>
    </div>
    <div class="baseball-params-by-team-header">
      <div class="params-content">
        <GameParamNumberInput
          :param="pitcherAdjustementsStarting"
          :disabled="autoMode"
          @paramChange="onParamChange"
          @paramIncrease="onParamIncrease"
          @paramDecrease="onParamDecrease"
        />
        <GameParamNumberInput
          :param="pitcherAdjustementsRelief"
          :disabled="autoMode"
          @paramChange="onParamChange"
          @paramIncrease="onParamIncrease"
          @paramDecrease="onParamDecrease"
        />
        <GameParamNumberInput
          :param="uncertainty"
          :disabled="autoMode"
          @paramChange="onParamChange"
          @paramIncrease="onParamIncrease"
          @paramDecrease="onParamDecrease"
        />
      </div>
      <Button
        v-if="hasTableData"
        :disabled="isSuspendAllPlayersDisabled"
        @click="toggleAllPlayersSuspend"
        class="suspend-btn"
        :class="{'suspend-active': isSuspendAllPlayersActive}"
      >
        <Icon :name="isSuspendAllPlayersActive ? 'pause' : 'play'" />
        <span>{{ isSuspendAllPlayersActive ? 'Suspend all players' : 'Unsuspend all players' }}</span>
      </Button>
    </div>
    <div class="baseball-by-team-player-params">
      <BaseballPlayerParamsByTeam
        :team-data="playerSetupTableDataByTeam"
        :team-data-filtered="playerSetupTableDataByTeamFiltered"
        :team-label="team.label"
        :team-side="team.side"
        @onPitcherHitterTabChange="onPitcherHitterTabChange"
      />
      <Spinner
        v-if="isProjectedPlayerParamsDataLoading"
        small
      />
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  filter, map, includes, toUpper,
  reduce, omit, find, cloneDeep, forEach,
  findIndex, orderBy, has, last,
} from 'lodash';
import {
  formatPlayerName,
  baseballPlayerParamsSetup,
} from '@/services/helpers/player-setup-mapper';
import {
  getSportNameBySportId,
} from '@/services/helpers/mappings-mapper';
import TextInput from '@/components/common/TextInput';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Spinner from '@/components/common/Spinner';
import Tooltip from '@/components/common/Tooltip';
import GameParamNumberInput from '@/components/player-setup/common/GameParamNumberInput';
import BaseballAddPlayersModal from '@/components/player-setup/baseball/BaseballAddPlayersModal';
import BaseballPlayerParamsByTeam from '@/components/player-setup/baseball/BaseballPlayerParamsByTeam';

import * as api from '@/services/api';

export default {
  emits: ['paramChange', 'paramIncrease', 'paramDecrease', 'onPitcherHitterTabChange'],
  props: {
    team: {
      type: Object,
      required: true,
    },
    autoMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextInput,
    Button,
    Icon,
    GameParamNumberInput,
    BaseballAddPlayersModal,
    BaseballPlayerParamsByTeam,
    Spinner,
    Tooltip,
  },
  setup(props, { emit }) {
    const store = useStore();
    const searchValue = ref('');
    const gameParams = computed(() => store.getters.playerSetupGameParams);
    const teamSide = computed(() => props.team.side);
    // Team params
    const pitcherAdjustements = computed(() => gameParams.value?.pitcherAdjustments);
    const pitcherAdjustementsStarting = computed(() => (teamSide.value === 'Home' ? pitcherAdjustements.value?.homeStarting : pitcherAdjustements.value?.awayStarting));
    const pitcherAdjustementsRelief = computed(() => (teamSide.value === 'Home' ? pitcherAdjustements.value?.homeRelief : pitcherAdjustements.value?.awayRelief));
    const uncertainty = computed(() => (teamSide.value === 'Home' ? gameParams.value?.uncertainty?.home : gameParams.value?.uncertainty?.away));

    // Player add modal tabs config
    const baseballPlayersTabs = computed(() => {
      const tabs = [
        {
          key: 'pitchers',
          label: 'Pitchers',
        },
        {
          key: 'hitters',
          label: 'Hitters',
        },
      ];
      return tabs;
    });
    const activeTab = ref(baseballPlayersTabs.value[0]);
    const onTabChange = (tab) => { activeTab.value = tab; };
    // Team data
    const isAddPlayersModalActive = ref(false);
    const playerSetupData = computed(() => store.getters.playerSetupData);
    const teamLineup = computed(() => (props.team?.side === 'Home' ? playerSetupData.value?.homeLineup : playerSetupData.value?.awayLineup));
    const hasTeamLineup = computed(() => !!teamLineup.value?.length);
    const playerSetupTableData = computed(() => store.getters.playerSetupTableData);
    const playerSetupTableDataByTeam = computed(() => map(playerSetupTableData.value?.[props.team?.label], (player) => (
      {
        ...player,
        isInTeamLineup: includes(map(teamLineup.value, ({ playerId }) => playerId), player.playerId),
      }
    )));
    const playerSetupTableDataByTeamFiltered = computed(() => filter(playerSetupTableDataByTeam.value,
      (player) => player.playerName?.toLowerCase().includes(searchValue.value?.toLowerCase())));
    const hasTableData = computed(() => playerSetupTableData.value?.[props.team?.label]?.length);
    const isSuspendAllPlayersDisabled = computed(() => !find(playerSetupTableData.value?.[props.team?.label],
      ({ marketCodeDetails }) => find(marketCodeDetails, ({ isLocked }) => !isLocked)));
    const isSuspendAllPlayersActive = computed(() => find(playerSetupTableData.value?.[props.team?.label], ({ isSuspended, marketCodeDetails }) => !isSuspended
      && find(marketCodeDetails, ({ isLocked }) => !isLocked)));

    const allSquadData = computed(() => playerSetupData.value?.[props.team?.label]?.squad);
    const squadTeamData = computed(() => {
      if (activeTab.value.key === 'pitchers') {
        return filter(allSquadData.value, ({ position }) => position === 'PITCHER' || position === 'PITCHER_HITTER' || position === 'UNKNOWN' || !position);
      } if (activeTab.value.key === 'hitters') {
        return filter(allSquadData.value, ({ position }) => position === 'HITTER' || position === 'PITCHER_HITTER' || position === 'UNKNOWN' || !position);
      }
      return [];
    });

    const allPlayersToAdd = computed(() => {
      const alreadyAddedPitchersIds = map(filter(playerSetupTableDataByTeam.value, ({ isPitcher }) => isPitcher), ({ playerId }) => playerId);
      const alreadyAddedHittersIds = map(filter(playerSetupTableDataByTeam.value, ({ isHitter }) => isHitter), ({ playerId }) => playerId);
      const alreadyAddedPlayers = activeTab.value.key === 'pitchers' ? alreadyAddedPitchersIds : alreadyAddedHittersIds;
      const playerList = map(allSquadData.value, (player) => ({
        value: player.playerId,
        label: formatPlayerName(player.playerName),
        isInTeamLineup: includes(map(teamLineup.value, ({ playerId }) => playerId), player.playerId),
        isAlreadyAdded: includes(alreadyAddedPlayers, player.playerId),
        isPitcher: includes(alreadyAddedPitchersIds, player.playerId),
        isHitter: includes(alreadyAddedHittersIds, player.playerId),
        playerIndex: player.playerIndex,
      }));
      return orderBy(playerList, 'label');
    });

    const playersToAdd = computed(() => filter(allPlayersToAdd.value, (player) => includes(map(squadTeamData.value, ({ playerId }) => playerId), player.value)));

    const isProjectedPlayerParamsDataLoading = ref(false);

    const updatePlayerSearch = (value) => {
      searchValue.value = value;
    };
    const addPlayers = () => {
      isAddPlayersModalActive.value = true;
      store.dispatch('loadTeamSquad', { teamId: props.team?.teamId, sportId: playerSetupData.value.sportId });
    };
    const onModalClose = () => { isAddPlayersModalActive.value = false; };

    const loadTeamLineupPresets = () => {
      store.dispatch('getTeamLineupPreset', { teamId: props.team?.teamId });
    };

    const updateAlreadyAddedPlayers = (addedPlayers, updatedPlayers) => {
      const updatedTeamData = cloneDeep(playerSetupTableDataByTeam.value);
      forEach(addedPlayers, (playerId) => {
        const playerToUpdateIndex = findIndex(updatedTeamData, (player) => player.playerId === playerId);
        const updatedPlayer = find(updatedPlayers, (player) => player.value === playerId);
        const playerToUpdate = find(updatedTeamData, (player) => player.playerId === playerId);
        // We need to call baseballPlayerParamsSetup and set isPitcher and isHitter to true
        // So that we can get pitcher and hitter params
        // Then after that we set if the player really is a pitcher or a hitter which will determine in which tab it will be shown
        // Mapper that is called on the submit will set params of pitcher or hitter to null if the value is false
        const playerParams = baseballPlayerParamsSetup(
          {
            ...playerToUpdate,
            isPitcher: true,
            isHitter: true,
          },
        );
        const playerParamsUpdated = {
          ...playerParams,
          ...playerParams.playerParams,
          playerName: playerToUpdate.playerName,
          isPitcher: playerToUpdate.isPitcher || updatedPlayer.isPitcher,
          isHitter: playerToUpdate.isHitter || updatedPlayer.isHitter,
        };
        let nextLineupNumber = last(orderBy(filter(playerSetupTableDataByTeam.value, ({ isHitter }) => isHitter), 'hitterParams.lineupNumber'))?.hitterParams?.lineupNumber + 1 || 1;
        if (playerParamsUpdated.isHitter) {
          playerParamsUpdated.hitterParams = {
            ...playerParamsUpdated.hitterParams,
            lineupNumber: nextLineupNumber,
          };
          nextLineupNumber += 1;
        }

        delete playerParamsUpdated.playerParams;
        updatedTeamData[playerToUpdateIndex] = playerParamsUpdated;
      });

      const playerSetupTableDataPayload = {
        ...playerSetupTableData.value,
        [props.team.label]: updatedTeamData,
      };
      store.dispatch('setPlayerSetupTableData', playerSetupTableDataPayload);
    };

    const addSelectedPlayers = async ({
      addedPlayers, saveLineup, lineupName, updateLineup, lineupId,
    }) => {
      onModalClose();
      const addedPlayersIds = map(addedPlayers, ({ value }) => value);
      const alreadyAddedPlayers = map(filter(playerSetupTableDataByTeam.value, ({ playerId }) => includes(addedPlayersIds, playerId)), ({ playerId }) => playerId);
      if (alreadyAddedPlayers.length) { updateAlreadyAddedPlayers(alreadyAddedPlayers, addedPlayers); }
      const newPlayersToAdd = filter(addedPlayers, (player) => !includes(alreadyAddedPlayers, player.value));

      isAddPlayersModalActive.value = false;
      const side = toUpper(props.team?.side);
      let playerParams = baseballPlayerParamsSetup({ side, isPitcher: true, isHitter: true });
      playerParams = {
        ...playerParams.playerParams,
        marketCodeDetails: playerParams.marketCodeDetails,
        isSuspended: playerParams.isSuspended,
      };
      let projectedPlayerParams = [];
      isProjectedPlayerParamsDataLoading.value = true;
      try {
        const paramsData = await api.getProjectedPlayerParams({
          sport: getSportNameBySportId(playerSetupData.value.sportId).toLowerCase(),
          eventId: playerSetupData.value?.eventId,
          addedPlayers: addedPlayersIds,
        });
        projectedPlayerParams = reduce(
          paramsData.projectedPlayerParams,
          (allPlayers, player) => ({
            ...allPlayers,
            [player.playerParams?.playerId]: player.playerParams,
          }), {},
        );
      } catch {
        projectedPlayerParams = null;
      }
      isProjectedPlayerParamsDataLoading.value = false;

      let nextPlayerIndex = orderBy(playerSetupTableDataByTeam.value, 'playerPositionIndex')[playerSetupTableDataByTeam.value?.length - 1]?.playerPositionIndex || 0;
      let nextLineupNumber = last(orderBy(filter(playerSetupTableDataByTeam.value, ({ isHitter }) => isHitter), 'hitterParams.lineupNumber'))?.hitterParams?.lineupNumber + 1 || 1;
      const addedPlayersProjectedParams = reduce(
        newPlayersToAdd || [],
        (allPlayers, player) => {
          const playerId = player.value;
          let playerParamsUpdated = cloneDeep(playerParams);
          let projectedPlayerParamsUpdated = cloneDeep(projectedPlayerParams ? omit(projectedPlayerParams[playerId], ['marketCodeDetails', 'hitterParams.lineupNumber']) : {});
          const {
            isPitcher, isHitter, lineupNumber,
            reliefPitcherOrder, playerPositionIndex,
          } = player;
          const playerInfo = find(allSquadData.value, { playerId });
          nextPlayerIndex += 1;
          if (isHitter) {
            // If we don't get projected params for hitter we should update the lineupNumber inside our default player params
            if (!has(projectedPlayerParamsUpdated, 'hitterParams')) {
              playerParamsUpdated = {
                ...playerParamsUpdated,
                hitterParams: {
                  ...playerParamsUpdated.hitterParams,
                  lineupNumber: lineupNumber || nextLineupNumber,
                },
              };
            } else {
              // If we get projected params for hitter we should update the lineupNumber inside our projected player params
              projectedPlayerParamsUpdated = {
                ...projectedPlayerParamsUpdated,
                hitterParams: {
                  ...projectedPlayerParamsUpdated.hitterParams,
                  lineupNumber: lineupNumber || nextLineupNumber,
                },
              };
            }
            nextLineupNumber += 1;
          }
          if (isPitcher) {
            if (!has(projectedPlayerParamsUpdated, 'pitcherParams')) {
              playerParamsUpdated = {
                ...playerParamsUpdated,
                pitcherParams: {
                  ...playerParamsUpdated.pitcherParams,
                  reliefPitcherOrder: reliefPitcherOrder || projectedPlayerParamsUpdated?.pitcherParams?.reliefPitcherOrder || 0,
                },
              };
            } else {
              projectedPlayerParamsUpdated = {
                ...projectedPlayerParamsUpdated,
                pitcherParams: {
                  ...projectedPlayerParamsUpdated.pitcherParams,
                  reliefPitcherOrder: reliefPitcherOrder || projectedPlayerParamsUpdated?.pitcherParams?.reliefPitcherOrder || 0,
                },
              };
            }
          }
          // Here we loop through the list of added players IDs
          // 1. playerParamsUpdated
          // All the player params with default values as a fallback
          // 2. playerInfo
          // We add playerInfo which contains playerName and playerIndex which we need
          // 3. projectedPlayerParamsUpdated
          // Player params projections fetched from an API
          return [
            ...allPlayers,
            ...[{
              ...playerParamsUpdated,
              ...playerInfo,
              ...projectedPlayerParamsUpdated,
              lineup: true,
              isPitcher,
              isHitter,
              playerPositionIndex: playerPositionIndex || nextPlayerIndex,
            }],
          ];
        },
        [],
      );
      const updatedTeamData = [
        ...(playerSetupTableDataByTeam.value ? playerSetupTableDataByTeam.value : []),
        ...addedPlayersProjectedParams,
      ];

      const playerSetupTableDataPayload = {
        ...playerSetupTableData.value,
        [props.team.label]: updatedTeamData,
      };
      store.dispatch('setPlayerSetupTableData', playerSetupTableDataPayload);
      if (saveLineup) {
        store.dispatch('saveTeamLineupPreset', {
          playersData: playerSetupTableDataPayload,
          teamData: props.team,
          lineupName,
        }).then(() => loadTeamLineupPresets());
      }
      if (updateLineup) {
        store.dispatch('updateTeamLineupPreset', {
          playersData: playerSetupTableDataPayload,
          teamData: props.team,
          lineupName,
          lineupId,
        }).then(() => loadTeamLineupPresets());
      }
      store.dispatch('setSelectedLineupPresetByTeam', {
        teamId: props?.team?.teamId,
        selectedLineupPreset: null,
      });
    };

    const onParamChange = (value, param) => {
      emit('paramChange', value, param);
    };

    const onParamIncrease = (param) => {
      emit('paramIncrease', param);
    };

    const onParamDecrease = (param) => {
      emit('paramDecrease', param);
    };

    const onPitcherHitterTabChange = (tab) => {
      emit('onPitcherHitterTabChange', tab);
    };

    const toggleAllPlayersSuspend = () => {
      const action = isSuspendAllPlayersActive.value ? 'suspend' : 'unsuspend';
      store.dispatch('toggleAllPlayersSuspend', {
        teamLabel: props.team?.label,
        action,
      });
    };

    return {
      searchValue,
      pitcherAdjustementsStarting,
      pitcherAdjustementsRelief,
      uncertainty,
      playersToAdd,
      isAddPlayersModalActive,
      baseballPlayersTabs,
      activeTab,
      playerSetupTableDataByTeam,
      isProjectedPlayerParamsDataLoading,
      updatePlayerSearch,
      addPlayers,
      onParamChange,
      onParamIncrease,
      onParamDecrease,
      onModalClose,
      addSelectedPlayers,
      onTabChange,
      hasTeamLineup,
      hasTableData,
      isSuspendAllPlayersDisabled,
      isSuspendAllPlayersActive,
      toggleAllPlayersSuspend,
      allPlayersToAdd,
      playerSetupTableDataByTeamFiltered,
      onPitcherHitterTabChange,
    };
  },
};
</script>

<style lang="scss">
.baseball-team-params-root {
  width: 100%;
  .baseball-team-params-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px 0;

    .team-info {
      display: flex;
      align-items: center;
      gap: 8px;
      .team-name {
        font-size: 16px;
        font-weight: 600;
      }
      .lineup-indicator {
        border-radius: 4px;
        padding: 2px;
        background-color: $gray700;
        &.lineup-available {
          background-color: $brandPrimary500;
        }
        .tooltip-element {
          display: flex;
          align-items: center;
        }
        .icon {
          display: inline-block;
          width: 18px;
          height: 18px;
          svg path {
            stroke: $white;
          }
        }
      }
    }
    .header-actions {
      display: flex;
      align-items: center;
      gap: 8px;
      .text-field {
        height: 32px;
      }
      .button{
        height: 32px;
        .icon svg path {
          fill: $white;
          stroke: none;
        }
      }
    }
  }
  .baseball-params-by-team-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    .params-content {
      display: flex;
      gap: 16px;
      align-items: center;
    }
  }
  .baseball-by-team-player-params {
    position: relative;
    height: calc(100% - 48px - 46px);
    .spinner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 200;
    }
  }
}
</style>
