<template>
  <div class="player-setup-scoreboard-root">
    <div
      class="player-setup-header-scoreboard"
    >
      <UnsavedChangesPopup
        v-if="isChangingModeUnsavedDataPopupActive"
        :message="'Any unsubmitted updates to players and game params will be lost. Are you sure you want to proceed?'"
        @onLeaveCancel="() => { isChangingModeUnsavedDataPopupActive = false; }"
        @onLeaveConfirm="reloadData"
      />
      <div
        class="scoreboard-details"
      >
        <div
          class="date-time-badge"
          :class="{ 'prematch': prematch, 'live': live, 'finished': finished }"
        >
          <div v-if="!finished">
            <span>{{ dateTime?.date }}</span>
            <span>{{ dateTime?.time }}</span>
          </div>
          <div v-else>
            <span>Game</span>
            <span>over</span>
          </div>
        </div>
        <div class="team-score-info">
          <div class="info">
            <div class="team-info">
              <span>{{ teamA.name }}</span>
              <span class="versus-indicator">{{ eventData.isUSAView ? '@' : 'v' }}</span>
            </div>
            <div class="score-info">
              {{ prematch ? '-' : teamA.score }}
            </div>
          </div>
          <div class="info">
            <div class="team-info">
              <span>{{ teamB.name }}</span>
            </div>
            <div class="score-info">
              {{ prematch ? '-' : teamB.score }}
            </div>
          </div>
        </div>
      </div>
      <div class="active-tab-actions">
        <div
          v-if="selectedTab === 'game-params'"
          class="auto-mode-root player-projections-action"
        >
          <Button
            :loading="isApprovingPrices"
            :disabled="isPricesApproved"
            @click="approvePrices"
          >
            {{ isPricesApproved ? 'Prices Approved' : 'Approve Prices' }}
          </Button>
          <Tooltip
            :text="'Auto mode inputs parameters automatically'"
            left
          >
            <Icon :name="'info'" />
          </Tooltip>
          <Toggle
            :disabled="isInferActive"
            :model-value="isSelectedTabAutoMode"
            @update:model-value="updateSelectedTabSelectedMode"
          />
          <Tooltip
            :text="'Auto mode for player parameters'"
            left
          >
            <span>Auto mode</span>
          </Tooltip>
          <div
            class="player-setup-header-projections-available"
          >
            <Tooltip
              :text="`${ gameProjectionsAvailable ? 'Game projections available' : 'No game projections available' }`"
              left
            >
              <div
                class="projections-available-indicator"
                :class="{ 'available': gameProjectionsAvailable }"
              />
            </Tooltip>
          </div>
          <Button
            @click="fetchGameProjections"
            :disabled="fetchGameProjectionsDisabled || isSelectedTabAutoMode"
            :class="['game-projections-button', {'disabled': fetchingGameProjections}]"
            :icon="'refresh-ccw-1'"
            :loading="fetchingGameProjections"
          >
            Fetch game projections
          </Button>
        </div>
        <div
          v-if="selectedTab === 'player-params'"
          class="player-projections-action"
        >
          <div
            class="player-setup-header-projections-available"
          >
            <Tooltip
              :text="`${ playerProjectionsAvailable ? 'Player projections available' : 'No player projections available' }`"
              left
            >
              <div
                class="projections-available-indicator"
                :class="{ 'available': playerProjectionsAvailable }"
              />
            </Tooltip>
          </div>
          <Button
            @click="fetchPlayerProjections"
            :disabled="fetchPlayerProjectionsDisabled || isSelectedTabAutoMode"
            :class="['player-projections-button', {'disabled': fetchingPlayerProjections}]"
            :icon="'refresh-ccw-1'"
            :loading="fetchingPlayerProjections"
          >
            Fetch player projections
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  includes, split, replace, filter,
} from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import { approveEventPrices } from '@/services/api';
import Event from '@/services/helpers/event';
import Basketball from '@/services/helpers/basketball';
import Football from '@/services/helpers/football';
import Hockey from '@/services/helpers/hockey';
import sportIds from '@/services/helpers/sports';
import Button from '@/components/common/Button';
import Tooltip from '@/components/common/Tooltip';
import Toggle from '@/components/common/Toggle';
import Icon from '@/components/common/Icon';
import UnsavedChangesPopup from '@/components/templates/sport-template/common/UnsavedChangesPopup';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  HOCKEY_ID,
} = sportIds;

export default {
  props: {
    selectedTab: {
      type: String,
      default: 'game-params',
    },
  },
  components: {
    Button,
    Tooltip,
    Toggle,
    Icon,
    UnsavedChangesPopup,
  },
  setup(props) {
    const store = useStore();
    const eventData = computed(() => store.getters.playerSetupData || {});
    const prematch = computed(() => Event.isPrematch(eventData.value));
    const live = computed(() => Event.isLive(eventData.value) && eventData.value.period !== 'EVENT_COMPLETED');
    const finished = computed(() => Event.isFinished(eventData.value));
    const teamA = computed(() => eventData.value.teamA || {});
    const teamB = computed(() => eventData.value.teamB || {});
    const isTeamAInPossession = computed(() => (eventData.value.isUSAView ? eventData.value.teamInPossession === 'AWAY' : eventData.value.teamInPossession === 'HOME'));
    const isTeamBInPossession = computed(() => (eventData.value.isUSAView ? eventData.value.teamInPossession === 'HOME' : eventData.value.teamInPossession === 'AWAY'));
    const fetchingPlayerProjections = ref(false);
    const fetchingGameProjections = ref(false);
    const playerProjectionsAvailable = computed(() => store.getters.isPlayerSetupProjectionsAvailable);
    const gameProjectionsAvailable = computed(() => store.getters.paramsManagerGameProjectionsAvailable);
    const isSelectedTabAutoMode = computed(() => {
      let selectedMode;
      if (props.selectedTab === 'player-params') {
        selectedMode = store.getters.playerParamsSelectedMode;
      } else if (props.selectedTab === 'game-params') {
        selectedMode = store.getters.gameParamsSelectedMode;
      }
      return selectedMode === 'CALCULATED';
    });
    const isChangingModeUnsavedDataPopupActive = ref(false);
    const dataChanged = computed(() => store.getters.isPlayerSetupSubmitEnabled || store.getters.playerSetupInferActive);
    const sportUtils = computed(() => {
      switch (eventData.value.sportId) {
      case BASKETBALL_ID:
        return Basketball;
      case FOOTBALL_ID:
        return Football;
      case HOCKEY_ID:
        return Hockey;
      default:
        return null;
      }
    });
    const isInferActive = computed(() => store.getters.playerSetupInferActive);
    const dateTime = computed(() => {
      const { startsAt } = eventData.value || {};
      const formattedStartsAt = replace(
        startsAt ? format(zonedTimeToUtc(startsAt, 'UTC'), 'MMM d, yyyy @ hh:mm a') : '@',
        `, ${new Date().getFullYear()}`,
        '',
      );
      const [date, time] = filter(split(formattedStartsAt, '@'), Boolean);
      return date && time ? { date, time } : null;
    });
    const livePeriods = computed(() => sportUtils.value?.getLivePeriods(eventData.value) || []);
    const time = computed(() => {
      if (finished.value) return 'Finished';
      if (live.value && !includes(livePeriods.value, eventData.value.period)) return 'Live';
      if (!prematch.value) return sportUtils.value.getPeriodLabel(eventData.value);
      return 'Pregame';
    });
    const fetchPlayerProjectionsDisabled = computed(() => store.getters.playerSetupInferActive);
    const fetchGameProjectionsDisabled = computed(() => store.getters.playerSetupInferActive);
    const fetchPlayerProjections = () => {
      if (fetchingPlayerProjections.value) return;
      fetchingPlayerProjections.value = true;
      store.dispatch('fetchParamsManagerProjections', { type: 'player' })
        .finally(() => {
          fetchingPlayerProjections.value = false;
        });
    };
    const fetchGameProjections = () => {
      if (fetchingGameProjections.value) return;
      fetchingGameProjections.value = true;
      store.dispatch('fetchParamsManagerProjections', { type: 'game' })
        .finally(() => {
          fetchingGameProjections.value = false;
        });
    };

    const updateSelectedMode = () => {
      const newMode = isSelectedTabAutoMode.value ? 'MANUAL' : 'CALCULATED';
      let storeAction;
      if (props.selectedTab === 'player-params') {
        storeAction = store.dispatch('setPlayerParamsSelectedMode', newMode);
      } else if (props.selectedTab === 'game-params') {
        storeAction = store.dispatch('setGameParamsSelectedMode', newMode);
      }
      storeAction.then(() => {
        if (newMode === 'MANUAL') {
          store.dispatch('paramsSetupGameParamsCalcToManualSwitch');
        } else {
          store.dispatch('paramsSetupGameParamsManualToCalcSwitch');
        }
      });
      isChangingModeUnsavedDataPopupActive.value = false;
    };
    const updateSelectedTabSelectedMode = () => {
      if (dataChanged.value) {
        isChangingModeUnsavedDataPopupActive.value = true;
        return;
      }
      updateSelectedMode();
    };

    const reloadData = () => updateSelectedMode();

    const isApprovingPrices = ref(false);
    const isPricesApproved = computed(() => !!eventData.value.isPricesApproved);
    const approvePrices = async () => {
      isApprovingPrices.value = true;
      try {
        await approveEventPrices(eventData.value.eventId);
      } catch (error) {
        console.error(error);
        store.dispatch('addNotification', {
          message: 'Unable to approve prices. Please try again later!',
          type: 'error',
          duration: 5000,
        });
      } finally {
        isApprovingPrices.value = false;
      }
    };

    return {
      eventData,
      teamA,
      teamB,
      prematch,
      live,
      finished,
      time,
      isTeamAInPossession,
      isTeamBInPossession,
      dateTime,
      fetchingPlayerProjections,
      playerProjectionsAvailable,
      fetchPlayerProjectionsDisabled,
      fetchPlayerProjections,
      isSelectedTabAutoMode,
      updateSelectedTabSelectedMode,
      isChangingModeUnsavedDataPopupActive,
      reloadData,
      fetchGameProjections,
      fetchingGameProjections,
      fetchGameProjectionsDisabled,
      gameProjectionsAvailable,
      isInferActive,
      isApprovingPrices,
      isPricesApproved,
      approvePrices,
    };
  },
};
</script>

<style lang="scss">
.player-setup-scoreboard-root {
  padding: 0 16px;
  .player-setup-header-scoreboard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    border-bottom: 1px solid $gray400;
    border-radius: 0 0 12px 12px;
    background-color: $gray300;
    padding: 16px;
    .scoreboard-details {
      display: flex;
      gap: 8px;
      margin: 0;

      .date-time-badge {
        display: flex;
        align-items: center;
        padding: 4px;
        border-radius: 4px;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 11px;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        &.prematch {
          border: 1px solid $primary200;
          background-color: #E8EFFF;
        }
        &.live {
          border: 1px solid $success100;
          background-color: #E5F8EE;
        }
        &.finished {
          border: none;
          background-color: $gray500;
        }
      }
      .team-score-info {
        display: flex;
        flex-direction: column;
        gap: 2px;
        font-size: 14px;
        line-height: 16px;
        color: #323232;
        font-weight: 400;

        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 35px;
          .team-info {
            display: flex;
            align-items: center;
            gap: 4px;

            .versus-indicator {
              color: $gray600;
            }
          }
          .score-info {
            font-weight: 600;
          }
        }
      }
    }
    .active-tab-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .auto-mode-root {
        display: flex;
        align-items: center;
        gap: 4px;

        .icon {
          width: 16px;
          height: 16px;
           svg path {
            stroke: $gray600;
          }
        }
      }
      .player-projections-action {
        display: flex;
        align-items: center;
        gap: 8px;
        .player-setup-header-projections-available {
          height: 100%;
          display: flex;
          align-items: center;
          .projections-available-indicator {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #FFC531;
            position: relative;
            &.available {
              background-color: $success50;
            }
            &::before{
              content: '';
              width: 10px;
              height: 10px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              border-radius: 50%;
              border: 2px solid $white;
              background-color: transparent;
            }
          }
        }
        .player-projections-button {
          &.disabled {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

</style>
