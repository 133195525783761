<template>
  <div class="player-setup-event-search">
    <div class="event-search-header">
      <TextInput
        class="search-field"
        :model-value="eventSearch"
        @update:modelValue="updateSearch"
        icon="search"
        placeholder="Search event"
        small
      />
    </div>
    <div class="event-search-body">
      <Spinner
        v-if="eventsLoading"
        small
      />
      <p v-if="!eventsLoading && !events.length">
        No events found
      </p>
      <div
        v-if="!eventsLoading && events.length"
        class="event-items"
      >
        <div
          v-for="event in events"
          :key="event.eventId"
          class="event-item"
          @click="onSelectEvent(event.eventId)"
        >
          <div>{{ event.eventName }}</div>
          <div class="event-additional-info">
            <div class="additional-info-time">
              {{ formatDate(event.startsAt) }}
              <span class="info-time-cell">
                &bull;
                {{ formatTime(event.startsAt) }}
              </span>
            </div>
            <div class="additional-info-competition">
              {{ event.competition?.competitionName || '' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { debounce } from 'lodash';
import { format, zonedTimeToUtc } from 'date-fns-tz';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    TextInput,
    Spinner,
  },
  emits: ['eventSelected'],
  setup(_, { emit }) {
    const store = useStore();
    const eventSearch = ref('');
    const events = computed(() => store.getters.playerSetupEventList);
    const eventsLoading = computed(() => store.getters.playerSetupEventListLoading);

    const updateSearch = debounce((val) => {
      eventSearch.value = val;
      store.dispatch('loadSearchEventListForPlayerSetup', val);
    }, 500);

    const onSelectEvent = (eventId) => {
      emit('eventSelected', eventId);
    };

    const formatDate = (eventStartsAt) => format(zonedTimeToUtc(eventStartsAt, 'UTC'), 'MMM d');
    const formatTime = (eventStartsAt) => format(zonedTimeToUtc(eventStartsAt, 'UTC'), 'hh:mm a');

    onMounted(() => store.dispatch('loadSearchEventListForPlayerSetup', ''));

    return {
      eventSearch,
      events,
      eventsLoading,
      updateSearch,
      onSelectEvent,
      formatDate,
      formatTime,
    };
  },
};
</script>

<style lang="scss">
.player-setup-event-search {
  height: 300px;
  width: 370px;
  background-color: $white;
  border: 1px solid $gray500;
  box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);
  position: relative;
  overflow: hidden;
  border-radius: 4px;

  .event-search-header {
    height: 53px;
    padding: 8px;
    border-bottom: 1px solid $gray500;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $white;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .event-search-body {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: calc(100% - 53px);

    .event-items {
      width: 100%;
      padding: 8px;
      overflow: auto;
      height: 100%;

      .event-item {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        height: 55px;
        padding: 8px 4px;
        cursor: pointer;
        justify-content: center;

        &:hover {
            background-color: $gray300;
        }

        .event-additional-info {
          font-size: 12px;
          display: flex;
          color: #807f7f;
          .info-time-cell {
            color: #A9A9A9;
          }

          .additional-info-competition {
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
