<template>
  <div
    class="player-setup-content"
  >
    <PlayerByTeamSetup
      :team-data="teamA"
      :event="playerSetupData"
    />
    <PlayerByTeamSetup
      :team-data="teamB"
      :event="playerSetupData"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import PlayerByTeamSetup from './setup-by-team/PlayerByTeamSetup';

export default {
  components: {
    PlayerByTeamSetup,
  },
  setup() {
    const store = useStore();
    const playerSetupData = computed(() => store.getters.playerSetupData);
    const teamA = computed(() => ({
      ...store.getters.playerSetupData?.teamA,
      sportId: store.getters.playerSetupData?.sportId,
      teamLabel: 'teamA',
    }));
    const teamB = computed(() => ({
      ...store.getters.playerSetupData?.teamB,
      sportId: store.getters.playerSetupData?.sportId,
      teamLabel: 'teamB',
    }));

    return {
      teamA,
      teamB,
      playerSetupData,
    };
  },
};
</script>

<style lang="scss">
.player-setup-content {
    height: 100%;
    display: flex;

    .player-by-team-setup {
        width: 50%;

        &:first-child {
            border-right: 1px solid $gray400;
        }
    }
}
</style>
