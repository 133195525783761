<template>
  <div
    class="baseball-lineup-list-modal"
  >
    <div class="lineup-quick-view-title">
      Selected {{ activeTab?.key === 'pitchers' ? 'pitchers' : 'hitters' }}:
    </div>
    <div class="lineup-quick-view-player-list">
      <div
        class="player-item"
        v-for="(player, index) in addedPlayers"
        :key="player.playerId"
      >
        {{ index + 1 }}. {{ player.playerName }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    addedPlayers: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
  },
};
</script>

  <style lang="scss">
  .baseball-lineup-list-modal {
    position: absolute;
    width: 248px;
    background-color: $white;
    z-index: 1301;
    left: calc(100% + 4px);
    border-radius: 4px;
    top: 0;
    padding: 16px;
    .lineup-quick-view-title {
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      text-transform: uppercase;
      color: $gray700;
      padding-bottom: 8px;
    }
    .lineup-quick-view-player-list {
      font-size: 14px;
      color: $gray800;
      max-height: calc(536px - 20px);
      overflow-y: auto;
      .player-item {
        padding-bottom: 8px;
      }
    }
  }
  </style>
