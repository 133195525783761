<template>
  <div class="baseball-add-players-modal-root">
    <ConfirmLineupChangeModal
      :confirm-modal-active="confirmLineupChangeActive"
      :is-selected-lineup-preset="!!selectedLineupPreset"
      @onModalClose="onConfirmLineupSaveClose"
      @onSaveLineupConfirm="onSaveLineupConfirm"
      @onUpdateLineupConfirm="onUpdateLineupConfirm"
    />
    <ConfirmLineupDeleteModal
      :confirm-modal-active="confirmLineupDeleteActive"
      @onModalClose="onConfirmLineupDeleteClose"
      @onDeleteLineupConfirm="onDeleteLineupConfirm"
    />
    <Modal
      :title="`Add players for ${teamName}`"
      subtitle="Choose players you want to offer."
      :visible="isAddPlayersModalVisible"
      @close="onModalClose"
      class="add-players-modal-root"
    >
      <BaseballLineupQuickViewModal
        v-if="isAddPlayersModalVisible && quickViewAddedPlayers.length"
        :added-players="quickViewAddedPlayers"
        :active-tab="activeTab"
      />
      <div class="lineup-preset-dropdown-root">
        <Tooltip
          :text="'No lineup presets available, please add lineup and save preset'"
          :hide="!!savedLineupPresets.length"
          top
        >
          <Dropdown
            :label="selectedLineupPreset?.name || 'Select lineup preset'"
            :disabled="!savedLineupPresets.length"
          >
            <DropdownItem
              v-for="lineup in savedLineupPresets"
              :key="lineup.lineupId"
              @click="onSelectedLineupPresetChange(lineup)"
              clickable
            >
              {{ lineup.name }}
            </DropdownItem>
          </Dropdown>
        </Tooltip>
      </div>
      <div class="header-actions">
        <div
          @click="toggleSelectAll"
          :class="{ 'deselect-active': !isSelectAllActive }"
          class="select-all-players-toggle-root"
        >
          <Checkbox
            :model-value="!isSelectAllActive"
            @update:modelValue="toggleSelectAll"
          />
          {{ isSelectAllActive ? 'Select all players' : 'Deselect all players' }}
        </div>
        <div
          class="show-all-players-toggle-root"
          @click="toggleShowAllPlayersActive"
        >
          <span>Show all players</span>
          <Toggle
            :model-value="isShowAllPlayersActive"
            @update:model-value="toggleShowAllPlayersActive"
          />
        </div>
      </div>
      <ParamsTabs
        v-if="playerTabs.length"
        class="baseball-players-tabs"
        :tabs="playerTabs"
        :active-tab="activeTab"
        @onTabChange="onTabChange"
      />
      <div
        v-if="!playersToAdd.length && !playersListLoading && !playerSearch"
        class="no-players-message"
      >
        <p>
          No players for selected team
        </p>
        <Button
          icon="link-external"
          @click="openTeamEdit"
        >
          Team edit
        </Button>
      </div>
      <div
        v-else
        class="modal-data"
      >
        <div class="search-field-container">
          <TextInput
            class="search-field"
            :model-value="playerSearch"
            @update:modelValue="updateSearch"
            icon="search"
            placeholder="Search player"
            small
          />
          <Button
            icon="link-external"
            @click="openTeamEdit"
          >
            Team edit
          </Button>
        </div>
        <div
          v-if="!playersToAddFiltered.length && !playersListLoading"
          class="no-players-message"
        >
          <p>
            No search results
          </p>
        </div>
        <div class="items-to-add">
          <Spinner
            v-if="playersListLoading"
            small
          />
          <div
            v-for="item in playersToAddFiltered"
            :key="item.value"
            class="player-to-add-item"
          >
            <div class="player-action">
              <Checkbox
                :model-value="isPlayerAdded(item.value)"
                :disabled="item.isAlreadyAdded"
                @update:modelValue="playerToggled(item.value)"
              />
              <p
                @click="playerToggled(item.value)"
                :title="item.playerIndex"
              >
                {{ item.label }}
              </p>
            </div>
            <div class="player-info-wrapper">
              <div class="player-info">
                <Tooltip
                  :text="item?.isInTeamLineup ? 'Player is in lineup' : 'Player is not in lineup'"
                  left
                >
                  <div
                    class="player-lineup-indicator"
                    :class="{ 'is-in-lineup': item?.isInTeamLineup }"
                  >
                    <Icon
                      :name="item?.isInTeamLineup ? 'users-check' : 'users-x'"
                    />
                  </div>
                </Tooltip>
              </div>
              <div class="player-info mapping-projections">
                <Tooltip
                  :text="isPlayerMapped(item.value) ? 'Player is mapped for projections' : 'Player is not mapped for projections'"
                  left
                >
                  <Icon
                    :name="isPlayerMapped(item.value) ? 'link-not-broken' : 'link-broken'"
                    :class="{ 'is-mapped': isPlayerMapped(item.value) }"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="playersToAdd.length"
        class="modal-footer"
      >
        <div
          v-if="selectedLineupPreset"
          @click="deleteLineupPreset"
          class="delete-preset-btn"
        >
          Delete preset
        </div>
        <div class="modal-actions">
          <Button
            variant="secondary"
            :disabled="saveAsLineupPresetBtnDisabled"
            @click="saveAsPreset"
          >
            Save as preset
          </Button>
          <Button
            variant="primary"
            :disabled="addPlayersDisabled"
            @click="addSelectedPlayers"
          >
            Add players
          </Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>

import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import {
  find, filter, map, forEach,
  includes, toLower, findIndex,
  cloneDeep, get, orderBy,
} from 'lodash';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import TextInput from '@/components/common/TextInput';
import Checkbox from '@/components/common/Checkbox';
import Spinner from '@/components/common/Spinner';
import sportIds from '@/services/helpers/sports';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import ParamsTabs from '@/components/player-setup/ParamsTabs';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import Toggle from '@/components/common/Toggle';
import ConfirmLineupChangeModal from '../common/ConfirmLineupChangeModal';
import ConfirmLineupDeleteModal from '../common/ConfirmLineupDeleteModal';
import BaseballLineupQuickViewModal from './BaseballLineupQuickViewModal';

const {
  BASEBALL_ID,
} = sportIds;

export default {
  components: {
    Modal,
    Button,
    TextInput,
    Checkbox,
    Spinner,
    ParamsTabs,
    Icon,
    Tooltip,
    Dropdown,
    DropdownItem,
    Toggle,
    ConfirmLineupChangeModal,
    ConfirmLineupDeleteModal,
    BaseballLineupQuickViewModal,
  },
  emits: [
    'onClose',
    'onAddSelectedPlayers',
    'onSelectedTabChange',
  ],
  props: {
    teamName: {
      type: String,
      default: () => '',
    },
    playersToAdd: {
      type: Array,
      default: () => [],
    },
    allPlayersToAdd: {
      type: Array,
      default: () => [],
    },
    isVisible: {
      type: Boolean,
      default: () => false,
    },
    playerTabs: {
      type: Array,
      default: () => [],
      required: false,
    },
    activeTab: {
      type: Object,
      default: () => {},
      required: false,
    },
    teamId: {
      type: String,
      default: () => '',
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const playersListLoading = computed(() => store.getters.teamSquadListLoading);
    const playerSearch = ref('');
    const isShowAllPlayersActive = ref(false);
    const playersToAddFiltered = computed(() => filter(
      // If the show all players is active, we should show all players even those that are already added
      filter(props.playersToAdd, (player) => (!isShowAllPlayersActive.value ? !player.isAlreadyAdded : true)),
      (player) => includes(toLower(player.label), toLower(playerSearch.value)),
    ));

    const addedPlayers = ref([]);
    const quickViewAddedPlayers = computed(() => {
      let allPlayers = [];
      const alreadyAddedPlayers = map(
        filter(props.playersToAdd, ({ isAlreadyAdded, isPitcher, isHitter }) => (props.activeTab?.key === 'pitchers' ? isPitcher : isHitter) && isAlreadyAdded),
        (player) => ({
          playerId: player.value,
          playerName: player.label,
        }),
      );
      const alreadyAddedPlayersIds = map(alreadyAddedPlayers, ({ playerId }) => playerId);
      let notSubmittedPlayers = filter(addedPlayers.value, ({ isPitcher, isHitter }) => (props.activeTab?.key === 'pitchers' ? isPitcher : isHitter));
      notSubmittedPlayers = filter(notSubmittedPlayers, ({ value }) => !includes(alreadyAddedPlayersIds, value));
      notSubmittedPlayers = map(notSubmittedPlayers, ({ value }) => ({
        playerId: value,
        playerName: find(props.playersToAdd, (player) => player.value === value)?.label,
      }));

      allPlayers = [
        ...alreadyAddedPlayers,
        ...notSubmittedPlayers,
      ];

      return orderBy(allPlayers, 'playerName');
    });
    const addPlayersDisabled = computed(() => !addedPlayers.value.length);
    const isSelectAllActive = computed(() => {
      let addedPlayersByType;
      let alreadyAddedPlayersCount;
      if (props.activeTab.key === 'pitchers') {
        addedPlayersByType = filter(addedPlayers.value, (player) => player.isPitcher);
        alreadyAddedPlayersCount = filter(props.playersToAdd, ({ isPitcher }) => isPitcher).length;
      } else if (props.activeTab.key === 'hitters') {
        addedPlayersByType = filter(addedPlayers.value, (player) => player.isHitter);
        alreadyAddedPlayersCount = filter(props.playersToAdd, ({ isHitter }) => isHitter).length;
      }
      return (props.playersToAdd.length - alreadyAddedPlayersCount) !== addedPlayersByType.length;
    });

    const toggleShowAllPlayersActive = () => {
      isShowAllPlayersActive.value = !isShowAllPlayersActive.value;
    };

    const onModalClose = () => {
      emit('onClose');
      addedPlayers.value = [];
      playerSearch.value = '';
      store.dispatch('setSelectedLineupPresetByTeam', {
        teamId: props.teamId,
        selectedLineupPreset: null,
      });
      emit('onSelectedTabChange', props.playerTabs[0]);
    };
    const updateSearch = (val) => { playerSearch.value = val; };

    const isPlayerAdded = (val) => {
      if (find(props.playersToAdd, ({ value }) => value === val)?.isAlreadyAdded) {
        return true;
      }
      if (props.activeTab.key === 'pitchers') {
        return !!find(addedPlayers.value, (player) => player.value === val && player.isPitcher);
      }
      return !!find(addedPlayers.value, (player) => player.value === val && player.isHitter);
    };
    const playerToggled = (val) => {
      const player = find(addedPlayers.value, ({ value }) => value === val);
      // If the player is not added yet, add him to the list
      if (!player) {
        addedPlayers.value.push({
          value: val,
          isPitcher: props.activeTab.key === 'pitchers',
          isHitter: props.activeTab.key === 'hitters',
        });
        return;
      }
      // If the player is added we need to update him
      const playerIndex = findIndex(addedPlayers.value, ({ value }) => value === val);
      const addedPlayersUpdated = cloneDeep(addedPlayers.value);

      if (props.activeTab.key === 'pitchers') {
        player.isPitcher = !player.isPitcher;
      } else if (props.activeTab.key === 'hitters') {
        player.isHitter = !player.isHitter;
      }

      // If player isn't a pitcher or a hitter, remove him from the list
      if (!player.isPitcher && !player.isHitter) {
        addedPlayers.value = filter(addedPlayers.value, ({ value }) => value !== val);
        return;
      }

      // Update the player in the list
      addedPlayersUpdated[playerIndex] = player;
    };

    const toggleSelectAll = () => {
      if (isSelectAllActive.value) {
        const playersValues = map(props.playersToAdd, (player) => player.value);
        forEach(playersValues, (val) => {
          let alreadyAddedByType;
          if (props.activeTab.key === 'pitchers') {
            alreadyAddedByType = find(addedPlayers.value, (player) => player.value === val && player.isPitcher)
            || find(props.playersToAdd, (player) => player.value === val && player.isPitcher);
          } else if (props.activeTab.key === 'hitters') {
            alreadyAddedByType = find(addedPlayers.value, (player) => player.value === val && player.isHitter)
            || find(props.playersToAdd, (player) => player.value === val && player.isHitter);
          }
          if (!alreadyAddedByType) {
            // If the player is not added by type we should check if it is added at all
            const alreadyAdded = find(addedPlayers.value, (player) => player.value === val);
            if (alreadyAdded) {
              // If the player is added, we should update him
              const playerIndex = findIndex(addedPlayers.value, (player) => player.value === val);
              addedPlayers.value[playerIndex] = {
                value: val,
                isPitcher: props.activeTab.key === 'pitchers' || addedPlayers.value[playerIndex].isPitcher,
                isHitter: props.activeTab.key === 'hitters' || addedPlayers.value[playerIndex].isHitter,
              };
            } else {
              // If the player is not added, we should add him
              addedPlayers.value.push({
                value: val,
                isPitcher: props.activeTab.key === 'pitchers',
                isHitter: props.activeTab.key === 'hitters',
              });
            }
          }
        });
      } else {
        // If the select all is active, we should deselect all by type
        if (props.activeTab.key === 'pitchers') {
          addedPlayers.value = map(addedPlayers.value, (player) => ({
            ...player,
            isPitcher: false,
          }));
        } else {
          addedPlayers.value = map(addedPlayers.value, (player) => ({
            ...player,
            isHitter: false,
          }));
        }

        // At the end we should remove players that are not pitchers or hitters
        addedPlayers.value = filter(addedPlayers.value, (player) => player.isPitcher || player.isHitter);
      }
    };

    const addSelectedPlayers = (data = {}) => {
      const {
        saveLineup = false, lineupName, updateLineup = false, lineupId,
      } = data;
      emit('onAddSelectedPlayers', {
        addedPlayers: addedPlayers.value,
        saveLineup,
        lineupName,
        updateLineup,
        lineupId,
      });
      addedPlayers.value = [];
      playerSearch.value = '';
    };

    const openTeamEdit = () => {
      const { query } = route;
      const { href } = router.resolve({
        name: 'teamManualEdit',
        query: {
          ...query,
          ...(query.sport ? {} : { sport: BASEBALL_ID }),
          teamId: props.teamId,
        },
      });
      window.open(href, '_blank');
    };

    const onTabChange = (tab) => emit('onSelectedTabChange', tab);

    // Lineup preset configuration
    const savedLineupPresets = computed(() => get(store.getters.lineupPresetsByTeam, props.teamId));
    const selectedLineupPreset = computed(() => get(store.getters.selectedLineupPresetByTeam, props.teamId));
    const confirmLineupChangeActive = ref(false);
    const confirmLineupDeleteActive = ref(false);
    const saveAsLineupPresetBtnDisabled = computed(() => !addedPlayers.value.length && !find(props.playersToAdd, ({ isAlreadyAdded }) => isAlreadyAdded));

    const isAddPlayersModalVisible = computed(() => props.isVisible && !confirmLineupChangeActive.value && !confirmLineupDeleteActive.value);

    const saveAsPreset = () => {
      confirmLineupChangeActive.value = true;
    };
    const onConfirmLineupSaveClose = () => {
      confirmLineupChangeActive.value = false;
    };
    const deleteLineupPreset = () => {
      confirmLineupDeleteActive.value = true;
    };

    const onConfirmLineupDeleteClose = () => {
      confirmLineupDeleteActive.value = false;
    };

    const mapLineupPlayers = ({ data, isPitcher = false, isHitter = false }) => {
      forEach(data, (lineupPlayer) => {
        const playerId = lineupPlayer?.playerParams?.playerId;
        const isPlayerLocked = !!find(props.allPlayersToAdd, (player) => player.value === playerId
          && player.isPitcher === isPitcher && player.isHitter === isHitter);
        if (isPlayerLocked) return;
        const isAdded = find(addedPlayers.value, (player) => player.value === playerId);
        if (!isAdded) {
          addedPlayers.value.push({
            value: playerId,
            isPitcher,
            isHitter,
            reliefPitcherOrder: lineupPlayer?.playerParams?.pitcherParams?.reliefPitcherOrder,
            lineupNumber: lineupPlayer?.playerParams?.hitterParams?.lineupNumber,
            playerPositionIndex: lineupPlayer?.playerPositionIndex,
          });
        } else {
          const playerIndex = findIndex(addedPlayers.value, (player) => player.value === playerId);
          const playerToUpdate = cloneDeep(addedPlayers.value[playerIndex]);
          addedPlayers.value[playerIndex] = {
            ...playerToUpdate,
            isPitcher: isPitcher || addedPlayers.value[playerIndex].isPitcher,
            isHitter: isHitter || addedPlayers.value[playerIndex].isHitter,
          };
        }
      });
    };

    const setPlayersFromLineup = () => {
      const lineupPitchers = selectedLineupPreset.value?.pitchers || [];
      const lineupHitters = selectedLineupPreset.value?.hitters || [];

      const pitchersInSquad = filter(lineupPitchers, (pitcher) => find(props.allPlayersToAdd, (player) => player.playerIndex === pitcher?.playerParams?.playerIndex));
      const hittersInSquad = filter(lineupHitters, (hitter) => find(props.allPlayersToAdd, (player) => player.playerIndex === hitter?.playerParams?.playerIndex));

      // Adding pitchers
      mapLineupPlayers({ data: pitchersInSquad, isPitcher: true });

      // Adding hitters
      mapLineupPlayers({ data: hittersInSquad, isHitter: true });

      // Notify user if there are players from lineup that are not in the squad
      const pitchersOutOfSquad = filter(lineupPitchers, (pitcher) => !find(props.allPlayersToAdd, (player) => player.playerIndex === pitcher?.playerParams?.playerIndex));
      const hittersOutOfSquad = filter(lineupHitters, (hitter) => !find(props.allPlayersToAdd, (player) => player.playerIndex === hitter?.playerParams?.playerIndex));

      if (pitchersOutOfSquad.length || hittersOutOfSquad.length) {
        store.dispatch('addNotification', {
          message: 'Some of the players in the preset are not in the squad, please update the squad.',
          type: 'warning',
        });
      }
    };

    const onSelectedLineupPresetChange = (selectedLineup) => {
      store.dispatch('setSelectedLineupPresetByTeam', {
        teamId: props.teamId,
        selectedLineupPreset: selectedLineup,
      }).then(() => {
        addedPlayers.value = [];
        setPlayersFromLineup();
      });
    };

    const loadTeamLineupPresets = () => {
      store.dispatch('getTeamLineupPreset', { teamId: props.teamId });
    };
    const onSaveLineupConfirm = (lineupName) => {
      addSelectedPlayers({ saveLineup: true, lineupName });
      confirmLineupChangeActive.value = false;
    };

    const onUpdateLineupConfirm = () => {
      addSelectedPlayers({
        updateLineup: true,
        lineupId: selectedLineupPreset.value.lineupId,
        lineupName: selectedLineupPreset.value.name,
      });
      confirmLineupChangeActive.value = false;
    };

    const onDeleteLineupConfirm = () => {
      store.dispatch('deleteTeamLineupPreset', { lineupId: selectedLineupPreset.value.lineupId }).then(() => {
        loadTeamLineupPresets();
        store.dispatch('setSelectedLineupPresetByTeam', {
          teamId: props.teamId,
          selectedLineupPreset: null,
        });
        confirmLineupDeleteActive.value = false;
      });
    };
    onMounted(() => {
      loadTeamLineupPresets();
    });
    const playerSetupMappedPlayersForProjections = computed(() => map(store.getters.playerSetupMappedPlayersForProjections, (player) => player.playerId));
    const isPlayerMapped = (playerId) => includes(playerSetupMappedPlayersForProjections.value, playerId);

    return {
      playersToAddFiltered,
      playersListLoading,
      addPlayersDisabled,
      isSelectAllActive,
      playerSearch,
      onModalClose,
      updateSearch,
      isPlayerAdded,
      playerToggled,
      toggleSelectAll,
      addSelectedPlayers,
      onTabChange,
      isShowAllPlayersActive,
      toggleShowAllPlayersActive,
      savedLineupPresets,
      saveAsPreset,
      confirmLineupChangeActive,
      isAddPlayersModalVisible,
      onConfirmLineupSaveClose,
      saveAsLineupPresetBtnDisabled,
      onSaveLineupConfirm,
      selectedLineupPreset,
      onSelectedLineupPresetChange,
      deleteLineupPreset,
      onConfirmLineupDeleteClose,
      confirmLineupDeleteActive,
      onDeleteLineupConfirm,
      onUpdateLineupConfirm,
      isPlayerMapped,
      openTeamEdit,
      quickViewAddedPlayers,
    };
  },
};
</script>

<style lang="scss">
.baseball-add-players-modal-root {
  .add-players-modal-root {
    .modal__dialog {
      position: relative;
    }
  }

  .baseball-players-tabs {
    padding: 0;
    height: auto;
    margin-bottom: 8px;
    .params-tabs-item {
      width: 100%;
    }
  }
  .header-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 16px 8px;

    .select-all-players-toggle-root {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    }

    .show-all-players-toggle-root {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    }
  }
  .search-field-container {
    display: flex;
    justify-content: space-between;
    gap: 4px;
    .search-field {
      flex: 1;
    }
  }
  .no-players-message {
    button {
      margin-top: 12px;
    }
  }
  .items-to-add {
    .player-to-add-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .player-lineup-indicator {
        display: flex;
        padding: 2px;

        .icon svg path {
          stroke: $gray800;
        }

        &.is-in-lineup {
          background-color: $brandPrimary500;
          border-radius: 4px;
          .icon svg path {
            stroke: $white;
          }
        }
      }

      .tooltip {
        .tooltip-element {
          display: flex;
          align-items: center;
          }
          .tooltip-text {
            font-weight: 400;
          }
      }

      .player-action {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .player-info {
        display: flex;
      }
    }
  }
  .player-info-wrapper {
    display: flex;
    gap: 8px;

    .player-info {
      display: flex;

      &.mapping-projections {
        .icon {
          width: 16px;
          height: 16px;

          svg {
            path {
              fill: #ff2e2d;
            }
          }

          &.is-mapped {
            svg {
              path {
                fill: #191414;;
              }
            }
          }
        }
      }
    }
  }
  .modal-footer {
    .delete-preset-btn {
      width: 100%;
      color: $error500;
      cursor: pointer;
    }
    .modal-actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
    }
  }
}
</style>
