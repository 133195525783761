<template>
  <div class="player-setup-footer">
    <div class="player-setup-footer-info">
      <div
        v-if="simulateNewPricesMessageActive"
        class="new-prices-available-message"
      >
        <Tooltip
          text="Press 'Simulate' in order to update current prices"
          right
        >
          <Icon name="alert-cycle" />
        </Tooltip>
        <span>New prices available</span>
      </div>
    </div>
    <div class="player-setup-footer-actions">
      <Button
        @click="onPlayerSetupInfer"
        :icon="'signal'"
        :disabled="!inferBtnActive"
      >
        Infer
      </Button>
      <Button
        @click="onSimulate"
        variant="primary"
        :icon="'signal'"
        class="simulate-btn"
        :disabled="!simulateBtnActive || isSimulateBtnFrozen"
      >
        <span>Simulate</span>
        <span v-if="isSimulateBtnFrozen">
          ({{ countdownValue }})
        </span>
      </Button>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';

export default {
  components: {
    Button,
    Icon,
    Tooltip,
  },
  setup() {
    const store = useStore();
    const inferBtnActive = computed(() => store.getters.playerSetupInferActive);
    const submitBtnActive = computed(() => store.getters.isPlayerSetupSubmitEnabled && !inferBtnActive.value);
    const simulateBtnActive = computed(() => (
      (store.getters.isPlayerSetupSimulateEnabled
      || store.getters.isPlayerSetupSimulateBySocketChangeEnabled)
      && !inferBtnActive.value
    ));
    const isSimulateBtnFrozen = computed(() => store.getters.isPlayerSetupPageSimulateBtnFrozen);
    const countdownValue = ref(10);
    const simulateNewPricesMessageActive = computed(() => store.getters.isPlayerSetupSimulateBySocketChangeEnabled);

    const onSimulate = () => {
      store.dispatch('simulateParamsSetup');
    };

    const onPlayerSetupInfer = () => store.dispatch('inferParamsManagerMarkets');

    watch(() => isSimulateBtnFrozen.value, (isFrozen) => {
      if (isFrozen) {
        const interval = setInterval(() => {
          countdownValue.value -= 1;
          if (countdownValue.value === 0) {
            store.dispatch('setIsPlayerSetupSimulateBtnFrozen', false);
            clearInterval(interval);
            countdownValue.value = 10;
          }
        }, 1000);
      }
    });

    return {
      submitBtnActive,
      simulateBtnActive,
      isSimulateBtnFrozen,
      countdownValue,
      inferBtnActive,
      simulateNewPricesMessageActive,
      onSimulate,
      onPlayerSetupInfer,
    };
  },
};
</script>

<style lang="scss">
.player-setup-footer {
    height: 66px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-top: 1px solid $gray400;

    .player-setup-footer-info {
      display: flex;
      align-items: center;

      .new-prices-available-message {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 4px;
        border-radius: 4px;
        background-color: rgba(232, 239, 255, 1);
        color: #0E3999;
        font-size: 14px;
        line-height: 16px;

        .tooltip-element {
          width: 16px;
          height: 16px;
          .icon {
            svg path {
              fill: $white;
              stroke: $white;
              &:first-child {
                fill: #165fff;
                stroke: #165fff;
              }
            }
          }
        }
      }
    }

    .player-setup-footer-actions {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: flex-end;
      .simulate-btn {
        .icon svg path {
          fill: $white;
          stroke: none;
        }
      }
      .button {
        &.button--disabled {
          .icon svg path {
            fill: $gray700;
            stroke: none;
          }
        }
      }
    }
}
</style>
